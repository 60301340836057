var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"visible":_vm.isOpen,"width":"70%","title":"Добавление заказа"},on:{"update:visible":function($event){_vm.isOpen=$event}}},[_c('div',{staticClass:"is-relative"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.orders},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":row.position}},[_c('el-table-column',{attrs:{"label":"Блюдо","prop":"title"}}),_c('el-table-column',{attrs:{"label":"Кол-во","prop":"quantity"}})],1)]}}])}),_c('el-table-column',{attrs:{"type":"selection","width":"55"}}),_c('el-table-column',{attrs:{"label":"Клиент","prop":"client"}}),_c('el-table-column',{attrs:{"label":"Адрес"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatAddress(row.address))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Примечание"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatComment(row.address))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Время доставки"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatTimeDelivery(row.timeDelivery))+" ")]}}])})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("Закрыть")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.saveOrdersInCourier}},[_vm._v("Добавить")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }