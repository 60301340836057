<template>
  <el-dialog :visible.sync="isOpen" width="70%" title="Добавление заказа">
    <div class="is-relative">
      <el-table :data="orders" style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="expand">
          <template slot-scope="{ row }">
            <el-table :data="row.position" style="width: 100%">
              <el-table-column label="Блюдо" prop="title" />
              <el-table-column label="Кол-во" prop="quantity" />
            </el-table>
          </template>
        </el-table-column>
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="Клиент" prop="client" />
        <el-table-column label="Адрес">
          <template slot-scope="{ row }">
            {{ formatAddress(row.address) }}
          </template>
        </el-table-column>
        <el-table-column label="Примечание">
          <template slot-scope="{ row }">
            {{ formatComment(row.address) }}
          </template>
        </el-table-column>
        <el-table-column label="Время доставки">
          <template slot-scope="{ row }">
            {{ formatTimeDelivery(row.timeDelivery) }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">Закрыть</el-button>
      <el-button type="primary" @click="saveOrdersInCourier">Добавить</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { useCourierOrders } from '@/app/couriers/courier-orders/useCourierOrders';
import { onBeforeMount } from '@vue/composition-api';
import store from '@/app/app-state';
import { COURIER_STORE_MODULE_NAME, courierStoreModule } from '@/app/couriers/courierStoreModule';

export default {
  props: {
    courierId: {
      type: Number,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    if (!store.hasModule(COURIER_STORE_MODULE_NAME)) {
      store.registerModule(COURIER_STORE_MODULE_NAME, courierStoreModule);
    }
    onBeforeMount(() => {
      if (!localStorage.getItem('clientTabs')) {
        localStorage.setItem(
          'clientTabs',
          JSON.stringify([
            {
              title: 'Сегодня',
              name: 'today',
            },
            {
              title: 'Завтра',
              name: 'tomorrow',
            },
          ])
        );
      }
    });
    const {
      orders,
      formatAddress,
      formatComment,
      handleSelectionChange,
      saveOrdersInCourier,
      open,
      isOpen,
      formatTimeDelivery,
    } = useCourierOrders(props);
    return {
      orders,
      formatAddress,
      formatComment,
      handleSelectionChange,
      saveOrdersInCourier,
      open,
      isOpen,
      formatTimeDelivery,
    };
  },
};
</script>
