import store from '@/app/app-state';
import { FETCH_ORDERS } from '@/app/clients/shared/state/types/action_types';
import { computed, ref, watch } from '@vue/composition-api';
import moment from 'moment';
import { COURIER_STORE_MODULE_NAME } from '@/app/couriers/courierStoreModule';

export const useCourierOrders = (props) => {
  const today = moment().format('YYYY-MM-DD');
  const editableTabsValue = ref('today');
  let multipleSelection = [];
  const isOpen = ref(false);

  const formatAddress = (address) => {
    if (address) {
      if (address.districts) {
        return `р-н ${address.districts.title}, ул. ${address.street} д. ${address.house}`;
      }
      return `ул. ${address.street}`;
    }
    return '-';
  };

  const formatComment = (address) => {
    if (address) {
      return address.comment;
    }
    return '-';
  };

  const handleSelectionChange = (val) => {
    multipleSelection = val;
  };

  const orders = computed(() =>
    store.state.clientsState.clientsData.orders_cal
      ? store.state.clientsState.clientsData.orders_cal.data.filter((c) => !c.courierId)
      : []
  );

  const reloadOrders = (name) => {
    if (name === 'tomorrow') {
      fetchOrders(moment().add(1, 'days').format('YYYY-MM-DD'));
      return;
    }
    if (name === 'today') {
      fetchOrders(today);
      return;
    }
    fetchOrders(name);
  };

  // eslint-disable-next-line no-unused-vars
  let createDialogController = null;

  const open = () => {
    let resolve;
    let reject;
    const createDialogPromise = new Promise((ok, fail) => {
      resolve = ok;
      reject = fail;
    });
    createDialogController = { resolve, reject };
    isOpen.value = true;
    reloadOrders(props.date);
    return createDialogPromise;
  };

  watch(editableTabsValue, (name) => {
    reloadOrders(name);
  });

  const fetchOrders = (date) => {
    store.dispatch(FETCH_ORDERS, { date });
  };

  reloadOrders(props.date);

  const bindCourierToOrder = (bindingsArray) => {
    store.dispatch(`${COURIER_STORE_MODULE_NAME}/bindCourierToOrder`, { bindingsArray });
  };

  const saveOrdersInCourier = () => {
    bindCourierToOrder(
      multipleSelection.map((selected) => ({
        order_id: selected.orderId,
        courier_id: props.courierId,
      }))
    );
    reloadOrders(editableTabsValue.value);
    createDialogController.resolve(true);
    isOpen.value = false;
  };

  const formatTimeDelivery = (time) => {
    if (time === '-') {
      return time;
    }
    if (!time) {
      return '-';
    }
    if (time === 'NULL') {
      return '-';
    }
    return JSON.parse(time).join('-');
  };

  return {
    orders,
    formatAddress,
    formatComment,
    handleSelectionChange,
    saveOrdersInCourier,
    open,
    isOpen,
    formatTimeDelivery,
  };
};
