<template>
  <div>
    <el-row>
      <el-col :span="6">
        <div>
          <span>Имя:</span>
          <el-input v-model="courier.name" class="mt-2" size="small" @change="changeNameCourier" />
        </div>
      </el-col>
      <el-col :span="4" :offset="14" class="mt-4">
        <el-popover v-model="isVisibleCourierList" width="300">
          <el-date-picker
            v-model="dateCourierList"
            size="small"
            align="right"
            :picker-options="{ firstDayOfWeek: 1 }"
            style="width: 100%"
            class="mt-2 mb-2"
            format="dd MMMM yyyy"
            value-format="yyyy-MM-dd"
            placeholder="02 июля 2021"
          />
          <div style="text-align: right; margin: 0">
            <el-button type="info" size="small" style="width: 100%" @click="previewFile">
              Предпросмотр файла
            </el-button>
            <el-button
              type="primary"
              class="mt-2 ml-0"
              size="small"
              style="width: 100%"
              @click="generateFile"
            >
              Сгенерировать файл
            </el-button>
          </div>
          <el-button slot="reference" size="small" icon="el-icon-download" type="info">
            Маршрутный лист
          </el-button>
        </el-popover>
      </el-col>
      <el-col :span="24" class="mt-4">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="Маршрутные листы" name="1">
            <el-table :data="courier.files" :show-header="false">
              <el-table-column prop="url" label="Файл">
                <template slot-scope="{ row: { url } }">
                  <a :href="url" target="_blank">
                    <i class="el-icon-document" />
                    {{ url.split('/').pop() }}
                  </a>
                </template>
              </el-table-column>
              <el-table-column prop="created_at" label="Дата" />
              <el-table-column align="right" width="150">
                <template slot-scope="{ row }">
                  <el-button
                    type="info"
                    size="mini"
                    icon="el-icon-view"
                    @click="previewFileTable(row.url)"
                  />
                  <el-button
                    type="danger"
                    size="mini"
                    icon="el-icon-delete"
                    @click="deleteFile(row)"
                  />
                </template>
              </el-table-column>
            </el-table>
          </el-collapse-item>
        </el-collapse>
      </el-col>
      <el-col :span="24" class="mt-4">
        <div class="is-relative">
          <div class="add-button">
            <el-popover v-model="isVisibleAddTab" width="300">
              <el-date-picker
                v-model="date"
                size="small"
                align="right"
                :picker-options="{ firstDayOfWeek: 1 }"
                style="width: 100%"
                class="mt-2 mb-2"
                format="dd MMMM yyyy"
                value-format="yyyy-MM-dd"
                placeholder="02 июля 2021"
              />
              <div style="text-align: right; margin: 0">
                <el-button size="small" @click="visible1 = false"> Закрыть </el-button>
                <el-button type="primary" size="small" @click="addTab"> Добавить </el-button>
              </div>
              <el-button slot="reference" size="small" icon="el-icon-plus" />
            </el-popover>
          </div>
          <el-tabs v-model="editableTabsValue" type="card" closable @tab-remove="removeTab">
            <el-tab-pane
              v-for="item in editableTabs"
              :key="item.name"
              :label="item.title"
              :name="item.name"
            >
              <el-table-draggable :animate="300">
                <el-table
                  :data="courierOrders"
                  style="width: 100%"
                  :row-class-name="tableRowClassName"
                >
                  <el-table-column width="35">
                    <template> <i class="el-icon-d-caret"></i> </template>
                  </el-table-column>
                  <el-table-column prop="client.name" label="Клиент">
                    <template slot-scope="{ row }">
                      <router-link :to="`/client/${row.client.id}/info`">
                        {{ row.client.name }}
                      </router-link>
                    </template>
                  </el-table-column>
                  <el-table-column prop="client.phone" label="Телефон" />
                  <el-table-column label="Адрес">
                    <template slot-scope="{ row }">
                      {{ formatAddress(row.client.district, row.client.address) }}
                    </template>
                  </el-table-column>
                  <el-table-column label="Примечание">
                    <template slot-scope="{ row }">
                      {{ formatComment(row.client.address) }}
                    </template>
                  </el-table-column>
                  <el-table-column label="Время">
                    <template slot-scope="{ row }">
                      {{ formatTimeDelivery(row.client.timeDelivery) }}
                    </template>
                  </el-table-column>
                  <el-table-column align="right" width="200">
                    <template slot="header">
                      <el-button
                        type="primary"
                        size="small"
                        icon="el-icon-plus"
                        @click="createCourierOrder"
                      >
                        Добавить заказ
                      </el-button>
                    </template>
                    <template slot-scope="{ row }">
                      <!--                      <el-select-->
                      <!--                        v-model="row.courierId"-->
                      <!--                        filterable-->
                      <!--                        placeholder="Укажите курьера"-->
                      <!--                        @change="changeCourier(row.courierId, row.orderId)"-->
                      <!--                      >-->
                      <!--                        <el-option-->
                      <!--                          v-for="item in couriers"-->
                      <!--                          :key="item.value"-->
                      <!--                          :label="item.label"-->
                      <!--                          :value="item.value"-->
                      <!--                        />-->
                      <!--                      </el-select>-->
                      <el-button
                        type="primary"
                        size="small"
                        @click="deliveredOrder(row.orderId, row.isDelivered)"
                      >
                        {{ row.isDelivered ? 'Отметить доставку' : 'Отметить как доставлено' }}
                      </el-button>
                      <el-button
                        size="small"
                        type="danger"
                        icon="el-icon-delete"
                        class="ml-4"
                        @click="removeOrderInCourier(row.orderId)"
                      />
                    </template>
                  </el-table-column>
                </el-table>
              </el-table-draggable>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
    </el-row>
    <courier-orders
      ref="createCourierOrderRef"
      :courier-id="+$route.params.id"
      :date="editableTabsValue"
    />
    <documents-preview ref="documentsPreviewRef" />
  </div>
</template>

<script>
import ElTableDraggable from 'element-ui-el-table-draggable';
import CourierOrders from '@/app/couriers/courier-orders/courier-orders';
import store from '@/app/app-state';
import { COURIER_STORE_MODULE_NAME, courierStoreModule } from '@/app/couriers/courierStoreModule';
import { useCourierView } from '@/app/couriers/courier-view/useCourierView';
import { onBeforeMount } from '@vue/composition-api';
import DocumentsPreview from '@/app/documents/documents-preview';
import moment from 'moment';

export default {
  components: {
    DocumentsPreview,
    CourierOrders,
    ElTableDraggable,
  },
  data() {
    return {
      activeName: null,
    };
  },
  setup() {
    const today = moment().format('YYYY-MM-DD');

    if (!store.hasModule(COURIER_STORE_MODULE_NAME)) {
      store.registerModule(COURIER_STORE_MODULE_NAME, courierStoreModule);
    }

    const {
      courier,
      formatAddress,
      formatComment,
      courierOrders,
      couriers,
      changeCourier,
      editableTabs,
      editableTabsValue,
      removeTab,
      addTab,
      date,
      isVisibleAddTab,
      documentsPreviewRef,
      previewFileTable,
      createCourierOrderRef,
      createCourierOrder,
      dateCourierList,
      isVisibleCourierList,
      previewFile,
      generateFile,
      fetchCourier,
      removeOrderInCourier,
      changeNameCourier,
      deleteFile,
      formatTimeDelivery,
      tableRowClassName,
      deliveredOrder,
    } = useCourierView();

    onBeforeMount(() => {
      localStorage.setItem(
        'courierTabs',
        JSON.stringify([
          {
            title: moment().locale('ru').format('ll'),
            name: 'today',
          },
          {
            title: moment().add(1, 'd').locale('ru').format('ll'),
            name: 'tomorrow',
          },
          {
            title: moment().add(2, 'd').locale('ru').format('ll'),
            name: moment().add(2, 'd').format('YYYY-MM-DD'),
          },
          {
            title: moment().add(3, 'd').locale('ru').format('ll'),
            name: moment().add(3, 'd').format('YYYY-MM-DD'),
          },
          {
            title: moment().add(4, 'd').locale('ru').format('ll'),
            name: moment().add(4, 'd').format('YYYY-MM-DD'),
          },
        ])
      );
      fetchCourier(today);
    });

    return {
      courier,
      formatAddress,
      formatComment,
      courierOrders,
      couriers,
      changeCourier,
      editableTabs,
      editableTabsValue,
      removeTab,
      addTab,
      date,
      isVisibleAddTab,
      documentsPreviewRef,
      previewFileTable,
      createCourierOrderRef,
      createCourierOrder,
      dateCourierList,
      isVisibleCourierList,
      previewFile,
      generateFile,
      removeOrderInCourier,
      changeNameCourier,
      deleteFile,
      formatTimeDelivery,
      tableRowClassName,
      deliveredOrder,
    };
  },
};
</script>
<style lang="scss">
.add-button {
  position: absolute;
  right: 0;
  z-index: 50;
}
.el-table .success-row {
  background: #f0f9eb;
}
</style>
